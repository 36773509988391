import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HttpService } from "../http/HttpService";
import { HttpMethod, RequestOptions, ServiceRequestState } from "../http/HttpService.model";
import { ReduxState } from "../redux/CoreReducer";
import { UserActions } from "./UserActions";
import { User, UserListRequest, UserRequest, UserRequestType } from "./UserProvider.model";

export const UserProvider = () => {
  const userState = useSelector<ReduxState, UserRequest>(state => state.userReducer.userRequest);
  const userListState = useSelector<ReduxState, UserListRequest>(state => state.userReducer.userListRequest);

  const dispatch = useDispatch();

  // User request
  useEffect(() => {
    if (userState.status !== ServiceRequestState.Ready || !(userState.requestedBadgeId || userState.requestedId)) return;

    const props: RequestOptions = {
      url: `http://chrono.sfmux.com/api/users/${
        userState.requestedBadgeId ? "badge/" + userState.requestedBadgeId : userState.requestedId
      }`,
      method: HttpMethod.Get
    };

    dispatch(UserActions.setLoading(UserRequestType.User));

    HttpService.executeRequest<User>(props)
      .then(response => {
        if (!response.handle) {
          dispatch(UserActions.userResponse({ id: 0, handle: "", badgeKey: 0 }));
        } else {
          dispatch(UserActions.userResponse(response));
        }
      })
      .catch(err => {
        dispatch(UserActions.setError(UserRequestType.User, ServiceRequestState.ServiceFailure, err));
      });
  }, [dispatch, userState.requestedBadgeId, userState.requestedId, userState.status]);

  // User Update request
  useEffect(() => {
    if (userState.status !== ServiceRequestState.Ready || !userState.requestedUser) return;

    const user = userState.requestedUser;
    const props: RequestOptions = {
      url: `http://chrono.sfmux.com/api/users/${user.id}`,
      method: HttpMethod.Put,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify(user)
    };

    dispatch(UserActions.setLoading(UserRequestType.User));

    HttpService.executeRequest<User>(props)
      .then(response => {
        dispatch(UserActions.userResponse(response));
      })
      .catch(err => {
        dispatch(UserActions.setError(UserRequestType.User, ServiceRequestState.ServiceFailure, err));
      });
  }, [dispatch, userState.requestedUser, userState.status]);

  // User list request
  useEffect(() => {
    if (userListState.status !== ServiceRequestState.Ready) return;

    const props: RequestOptions = {
      url: "http://chrono.sfmux.com/api/users/all",
      method: HttpMethod.Get
    };

    dispatch(UserActions.setLoading(UserRequestType.UserList));

    HttpService.executeRequest<User[]>(props)
      .then(response => {
        dispatch(UserActions.userListResponse(response));
      })
      .catch(err => {
        dispatch(UserActions.setError(UserRequestType.UserList, ServiceRequestState.ServiceFailure, err));
      });
  }, [dispatch, userListState.status]);

  return null;
};
