import { ServiceRequestState } from "../http/HttpService.model";
import { ActionIdentifier } from "../redux/ActionIdentifier";
import { Action } from "./../redux/Action";
import { initialUserRequestState, UserRequestState, UserRequestType } from "./UserProvider.model";

export const userReducer = (state = initialUserRequestState, action: Action): UserRequestState => {
  switch (action.type) {
    case ActionIdentifier.UserRequest:
      return {
        ...state,
        userRequest: {
          ...initialUserRequestState.userRequest,
          status: ServiceRequestState.Ready,
          requestedId: action.payload.userId,
          requestedBadgeId: action.payload.badgeKey
        }
      };

    case ActionIdentifier.UserClear:
      return {
        ...state,
        userRequest: { ...initialUserRequestState.userRequest }
      };

    case ActionIdentifier.UserUpdate:
      return {
        ...state,
        userRequest: {
          ...initialUserRequestState.userRequest,
          status: ServiceRequestState.Ready,
          requestedUser: action.payload
        }
      };

    case ActionIdentifier.UserResponse:
      // eslint-disable-next-line no-case-declarations
      const newUserList = [...(state.userListRequest.response ?? [])];
      // eslint-disable-next-line no-case-declarations
      const userIndex = newUserList.findIndex(u => u.id === action.payload.id);

      if (userIndex > -1) {
        newUserList.splice(userIndex, 1, action.payload);
      } else {
        newUserList.push(action.payload);
      }

      return {
        ...state,
        userListRequest: {
          ...state.userListRequest,
          response: newUserList
        },
        userRequest: {
          ...state.userRequest,
          status: ServiceRequestState.Success,
          response: action.payload
        }
      };

    case ActionIdentifier.UserListRequest:
      if (state.userListRequest.status === ServiceRequestState.Ready || state.userListRequest.status === ServiceRequestState.Loading) {
        return state;
      }

      return {
        ...state,
        userListRequest: {
          ...initialUserRequestState.userListRequest,
          status: ServiceRequestState.Ready
        }
      };

    case ActionIdentifier.UserListResponse:
      return {
        ...state,
        userListRequest: {
          ...initialUserRequestState.userListRequest,
          status: ServiceRequestState.Success,
          response: action.payload
        }
      };

    case ActionIdentifier.UserSetError:
      if (action.payload.requestType === UserRequestType.User) {
        return {
          ...state,
          userRequest: {
            ...state.userRequest,
            error: action.payload.error,
            status: action.payload.errorType
          }
        };
      } else {
        return {
          ...state,
          userListRequest: {
            ...state.userListRequest,
            error: action.payload.error,
            status: action.payload.errorType
          }
        };
      }

    case ActionIdentifier.UserSetLoading:
      if (action.payload === UserRequestType.User) {
        return {
          ...state,
          userRequest: {
            ...state.userRequest,
            status: ServiceRequestState.Loading
          }
        };
      } else {
        return {
          ...state,
          userListRequest: {
            ...state.userListRequest,
            status: ServiceRequestState.Loading
          }
        };
      }

    default:
      return state;
  }
};
