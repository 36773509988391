import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { Action } from "./Action";
import { coreReducer, ReduxState } from "./CoreReducer";

const initialState = {};
const middleware = [thunk];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(coreReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

type dispatchThunkFunction = (dispatch: (action: Action) => void, getState: () => ReduxState) => void;
export type DispatchType = (action: Action | dispatchThunkFunction) => void;
