import { HttpErrorData,ServiceRequestState } from "../http/HttpService.model";
import { User } from "../user/UserProvider.model";

export enum ClockActionType {
  CheckIn = 1,
  CheckOut = 2
}

export interface ClockState {
  status: ServiceRequestState;
  error?: Error | HttpErrorData;
  user?: User;
  clockAction?: ClockActionType;
}

export const initialClockState: ClockState = {
  status: ServiceRequestState.Ready
};
