import * as request from "superagent";

import { HttpErrorData, HttpMethod, RequestOptions } from "./HttpService.model";

const connectionTimeoutInMS = 3000;
const responseTimeoutInMS = 8000;

const constructAndExecuteByMethod = <T>(props: RequestOptions, method: HttpMethod): Promise<T> => {
  const newProps: RequestOptions = {
    ...props,
    method: method
  };
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return HttpService.executeRequest<T>(newProps);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const httpServiceSuccessHandler = <T>(response: request.Response, options?: RequestOptions): Promise<T> => {
  const data: T = response.body || response.text;
  return Promise.resolve(data);
};

export const httpServiceRejectHandler = (url: string, reason: HttpErrorData): Promise<never> => Promise.reject(reason);

export abstract class HttpService {
  static buildRequest(props: RequestOptions): request.SuperAgentRequest {
    let req: request.SuperAgentRequest;
    switch (props.method) {
      case HttpMethod.Get:
        req = request.get(props.url);
        break;
      case HttpMethod.Post:
        req = request.post(props.url).send(props.data);
        break;
      case HttpMethod.Delete:
        req = request.del(props.url);
        break;
      case HttpMethod.Put:
        req = request.put(props.url).send(props.data);
        break;
      case HttpMethod.Options:
        req = request.options(props.url);
        break;
      default:
        throw new Error("Unknown HTTPMethod Type");
    }

    req = req.retry(props.retryCount);

    if (props.headers) {
      req = req.set(props.headers);
    }

    if (props.timeouts) {
      req = req.timeout({
        deadline: (props.timeouts.responseTimeout || responseTimeoutInMS) + (props.timeouts.connectionTimeout || connectionTimeoutInMS),
        response: props.timeouts.responseTimeout || responseTimeoutInMS
      });
    }

    if (props.type) {
      req = req.type(props.type);
    }

    if (props.accept) {
      props.accept.forEach(accept => (req = req.accept(accept)));
    }

    if (props.withCredentials) {
      req = req.withCredentials();
    }

    if (props.query) {
      props.query.forEach(query => (req = req.query(query)));
    }

    return req;
  }

  static get = <T>(props: RequestOptions): Promise<T> => constructAndExecuteByMethod<T>(props, HttpMethod.Get);
  static post = <T>(props: RequestOptions): Promise<T> => constructAndExecuteByMethod<T>(props, HttpMethod.Post);
  static put = <T>(props: RequestOptions): Promise<T> => constructAndExecuteByMethod<T>(props, HttpMethod.Put);
  static delete = <T>(props: RequestOptions): Promise<T> => constructAndExecuteByMethod<T>(props, HttpMethod.Delete);
  static options = <T>(props: RequestOptions): Promise<T> => constructAndExecuteByMethod<T>(props, HttpMethod.Options);

  static executeRequest<T>(props: RequestOptions): Promise<T> {
    const instance = HttpService.buildRequest(props);
    return instance
      .then(response => {
        return props.onSuccess ? props.onSuccess<T>(response, props) : httpServiceSuccessHandler<T>(response, props);
      })
      .catch((reason: HttpErrorData) => {
        return props.onError ? props.onError(props.url, reason) : httpServiceRejectHandler(props.url, reason);
      });
  }
}
