import { HttpErrorData,ServiceRequestState } from "../http/HttpService.model";

export interface User {
  handle: string;
  id: number;
  badgeKey: number;
}

export enum UserRequestType {
  User,
  UserList
}

interface BaseUserRequest {
  status: ServiceRequestState;
  error?: Error | HttpErrorData;
}

export interface UserListRequest extends BaseUserRequest {
  requestType: UserRequestType.UserList;
  response?: User[];
}

export interface UserRequest extends BaseUserRequest {
  requestedBadgeId?: number;
  requestedId?: number;
  requestedUser?: User;
  response?: User;
  requestType: UserRequestType.User;
}

export interface UserRequestState {
  userRequest: UserRequest;
  userListRequest: UserListRequest;
}

export const initialUserRequestState: UserRequestState = {
  userRequest: {
    status: ServiceRequestState.Ready,
    requestType: UserRequestType.User
  },
  userListRequest: {
    status: ServiceRequestState.Ready,
    requestType: UserRequestType.UserList
  }
};
