import { ServiceRequestState } from "../http/HttpService.model";
import { ActionIdentifier } from "../redux/ActionIdentifier";
import { Action } from "./../redux/Action";
import { ClockState,initialClockState } from "./ClockProvider.model";

export const clockReducer = (state = initialClockState, action: Action): ClockState => {
  switch (action.type) {
    case ActionIdentifier.ClockRequest:
      if (state.status === ServiceRequestState.Loading) {
        return state;
      }

      return {
        ...initialClockState,
        status: ServiceRequestState.Ready,
        user: action.payload.user,
        clockAction: action.payload.clockAction
      };

    case ActionIdentifier.ClockSetLoading:
      return {
        ...state,
        status: ServiceRequestState.Loading
      };

    case ActionIdentifier.ClockSetError:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.errorType
      };

    case ActionIdentifier.ClockResponse:
      return {
        ...state,
        status: ServiceRequestState.Success
      };

    default:
      return state;
  }
};
