export enum ActionIdentifier {
  ClockRequest = "ClockRequest",
  ClockResponse = "ClockResponse",
  ClockSetError = "ClockSetError",
  ClockSetLoading = "ClockSetLoading",
  UserListRequest = "UserListRequest",
  UserListResponse = "UserListResponse",
  UserClear = "UserClear",
  UserRequest = "UserRequest",
  UserResponse = "UserResponse",
  UserSetError = "UserSetError",
  UserSetLoading = "UserSetLoading",
  UserUpdate = "UserUpdate"
}
