import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HttpService } from "../http/HttpService";
import { HttpMethod, RequestOptions, ServiceRequestState } from "../http/HttpService.model";
import { ReduxState } from "../redux/CoreReducer";
import { ClockActions } from "./ClockActions";
import { ClockActionType, ClockState } from "./ClockProvider.model";

export const ClockProvider = () => {
  const clockState = useSelector<ReduxState, ClockState>(state => state.clockReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clockState.status === ServiceRequestState.Ready && clockState.user && clockState.clockAction) {
      const props: RequestOptions = {
        url: `http://chrono.sfmux.com/api/clock/${clockState.clockAction === ClockActionType.CheckIn ? "checkin" : "checkout"}`,
        method: HttpMethod.Post,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(clockState.user)
      };

      dispatch(ClockActions.setLoading());

      HttpService.executeRequest(props)
        .then(() => {
          dispatch(ClockActions.clockResponse());
        })
        .catch(err => {
          dispatch(ClockActions.setError(ServiceRequestState.ServiceFailure, err));
        });
    }
  }, [clockState.clockAction, clockState.status, clockState.user, dispatch]);

  return null;
};
