import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ClockActions } from "../../core/clock/ClockActions";
import { ClockProvider } from "../../core/clock/ClockProvider";
import { ClockActionType, ClockState } from "../../core/clock/ClockProvider.model";
import { ServiceRequestState } from "../../core/http/HttpService.model";
import { DispatchType } from "../../core/redux";
import { ReduxState } from "../../core/redux/CoreReducer";
import { UserActions } from "../../core/user/UserActions";
import { UserProvider } from "../../core/user/UserProvider";
import { User, UserRequest } from "../../core/user/UserProvider.model";

const submitHandle = (dispatch: DispatchType, badgeKey: number, handle: string) => {
  const user: User = { id: 0, badgeKey, handle };
  dispatch(UserActions.updateUser(user));
};

const submitBadge = (dispatch: DispatchType, badgeKey: number) => {
  dispatch(UserActions.getSingleUser(undefined, badgeKey));
};

interface StateData {
  user?: User;
  shouldClear?: boolean;
}

export const CheckIn = () => {
  const userData = useSelector<ReduxState, UserRequest>(state => state.userReducer.userRequest);
  const clockData = useSelector<ReduxState, ClockState>(state => state.clockReducer);

  const inputBadge = useRef(null);
  const inputHandle = useRef(null);
  const [state, setState] = useState<StateData>({ shouldClear: false });
  const dispatch = useDispatch();

  const postAction = (dispatch: DispatchType, user: User, actionType: ClockActionType) => {
    dispatch(ClockActions.clockRequest(user, actionType));
    setState({ ...state, shouldClear: true });
  };

  const onBadgeKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      submitBadge(dispatch, +inputBadge.current.value);
    }
  };

  const onHandleKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      submitHandle(dispatch, +inputBadge.current.value, inputHandle.current.value);
    }
  };

  // Clear the data once we've successfully submitted our clock action
  useEffect(() => {
    if (state.shouldClear && clockData.status === ServiceRequestState.Success) {
      inputBadge.current.value = "";
      inputHandle.current.disabled = false;
      inputHandle.current.value = "";
      setState({ ...state, user: undefined, shouldClear: false });
      dispatch(UserActions.clearUser());
    }
  }, [clockData.status, dispatch, state]);

  // Update the Handle once we've gotten a valid response.
  useEffect(() => {
    if (userData.response?.id && !state.user) {
      inputHandle.current.value = userData.response.handle;
      inputHandle.current.disabled = true;
      setState({ ...state, user: userData.response });
    }
  }, [state, userData.response]);

  return (
    <div id="checkin">
      <UserProvider />
      <ClockProvider />
      <div className="checkinBadgeRow">
        ID: <input name="badgeKey" ref={inputBadge} onKeyPress={onBadgeKeyPress} />
        <button name="getUser" onClick={() => submitBadge(dispatch, +inputBadge.current.value)} title={"Submit"}>
          Submit
        </button>
      </div>
      <div className="checkinHandleRow">
        Handle: <input name="handle" ref={inputHandle} onKeyPress={onHandleKeyPress} />
        <button name="saveUser" title={"Save"} onClick={() => submitHandle(dispatch, +inputBadge.current.value, inputHandle.current.value)}>
          Save
        </button>
      </div>
      <div className="checkInButtonRow">
        <button
          name="checkin"
          title={"Check In"}
          disabled={(state.user?.handle ?? "").length === 0}
          onClick={() => postAction(dispatch, state.user, ClockActionType.CheckIn)}>
          Check In
        </button>
        <button
          name="checkout"
          title={"Check Out"}
          disabled={(state.user?.handle ?? "").length === 0}
          onClick={() => postAction(dispatch, state.user, ClockActionType.CheckOut)}>
          Check Out
        </button>
      </div>
    </div>
  );
};
