import { combineReducers } from "redux";

import { clockReducer } from "./../clock/ClockReducer";
import { userReducer } from "./../user/UserReducer";

export const coreReducer = combineReducers({
  clockReducer,
  userReducer
});

export type ReduxState = ReturnType<typeof coreReducer>;
