import { HttpErrorData, ServiceRequestErrorType } from "../http/HttpService.model";
import { ActionIdentifier } from "../redux/ActionIdentifier";
import { createAction } from "../redux/CreateAction";
import { User } from "../user/UserProvider.model";
import { ClockActionType } from "./ClockProvider.model";

export abstract class ClockActions {
  static clockRequest(user: User, clockAction: ClockActionType) {
    return createAction(ActionIdentifier.ClockRequest, { user, clockAction });
  }

  static setLoading() {
    return createAction(ActionIdentifier.ClockSetLoading, null);
  }

  static setError(errorType: ServiceRequestErrorType, error: Error | HttpErrorData) {
    return createAction(ActionIdentifier.ClockSetError, { errorType, error });
  }

  static clockResponse() {
    return createAction(ActionIdentifier.ClockResponse, null);
  }
}
