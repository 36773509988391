import { ActionIdentifier } from "../redux/ActionIdentifier";
import { createAction } from "../redux/CreateAction";
import { HttpErrorData, ServiceRequestErrorType } from "./../http/HttpService.model";
import { User, UserRequestType } from "./UserProvider.model";

export abstract class UserActions {
  static getSingleUser(userId?: number, badgeKey?: number) {
    if (!(userId ?? badgeKey ?? false)) {
      throw Error("Invalid getSingleUser! Requires either userID or badgeKey");
    }
    return createAction(ActionIdentifier.UserRequest, { userId, badgeKey });
  }

  static getAllUsers() {
    return createAction(ActionIdentifier.UserListRequest, null);
  }

  static clearUser() {
    return createAction(ActionIdentifier.UserClear, null);
  }

  static updateUser(user: User) {
    return createAction(ActionIdentifier.UserUpdate, { ...user });
  }

  static setLoading(requestType: UserRequestType) {
    return createAction(ActionIdentifier.UserSetLoading, requestType);
  }

  static setError(requestType: UserRequestType, errorType: ServiceRequestErrorType, error: Error | HttpErrorData) {
    return createAction(ActionIdentifier.UserSetError, { errorType, error, requestType });
  }

  static userResponse(user: User) {
    return createAction(ActionIdentifier.UserResponse, { ...user });
  }

  static userListResponse(users: User[]) {
    return createAction(ActionIdentifier.UserListResponse, [...users]);
  }
}
