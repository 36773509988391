import * as request from "superagent";

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Options = "OPTIONS",
  Delete = "DELETE",
  Put = "PUT"
}

export interface HttpErrorData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  original?: any;
  name?: string;
  response: ErrorResponse;
  message?: string;
  stack?: string;
  status?: number;
}

export interface ErrorResponse {
  req: Request;
  text?: string;
  statusText: string;
  statusCode: number;
  status: number;
  statusType: number;
  redirect: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  unprocessableEntity: boolean;
  headers: object;
  header: object;
  type: string;
  links?: object;
  body?: object;
}

export interface Request {
  method: string;
  url: string;
  data: string;
  headers: object;
}

export interface RequestOptions {
  // Destination URL (CANNOT CONTAIN QUERY STRING)
  url: string;
  // Method of request
  method?: HttpMethod;
  // Data to add to request body (works for POST and PUT)
  data?: string;
  // Any headers to add
  headers?: object;
  // Number of times to retry connection (NOT BAD RESPONSES)
  retryCount?: number;
  // Timeout values in milliseconds
  timeouts?: {
    // Timeout for connection to server
    connectionTimeout?: number;
    // Timeout for response from server
    responseTimeout?: number;
  };
  // Content-Type string value
  type?: string;
  // Query string values
  query?: object[];
  // Accept Header values
  accept?: string[];
  // Send Credentials - Use for CORS requests
  withCredentials?: boolean;
  // Callback on successful request
  onSuccess?: <T>(response: request.Response, options?: RequestOptions) => Promise<T>;
  // Callback on failed request
  onError?: (url: string, reason: HttpErrorData) => Promise<never>;
}

export enum ServiceRequestState {
  Unknown,
  Ready,
  Loading,
  Success,
  ServiceFailure,
  ClientFailure,
  InvalidContent
}

export type ServiceRequestErrorType =
  | ServiceRequestState.ServiceFailure
  | ServiceRequestState.InvalidContent
  | ServiceRequestState.ClientFailure;
