import "./App.css";

import React from "react";

import { CheckIn } from "./features/checkin/Checkin";

export const App = () => {
  return (
    <div className="App">
      <CheckIn />
    </div>
  );
};
